/*change color of symbol of meus icon*/

.fa,
.fas,
.far,
.fab {
    color: rgb(55, 125, 184) !important;
}

.fa-chart-area,
.fa-calendar-day {
    color: rgb(255, 255, 255) !important;
}